import React from 'react'
import './footer.css'

function Footer() {
  return (
    <section id='footer'>
        Copyright @ Rayen Benhassen. All right received

    </section>
  )
}

export default Footer