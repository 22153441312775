import React from 'react';
import './work.css';
import hbibna from '../../assets/hbibna.png';
import portfolio from '../../assets/portfolio.png';


function works() {
  return (
    <section id='works'>
        <div className='worksSlog'>MY WORK</div>
        <div className='worksTitle'>Projects.</div>
        <div className='worksDesc'>The following projects reflect my ability to solve complex problems, work with different technologies, and manage projects effectively.</div>
        <div className='worksAll'>

            <div className='workBox'>
                <img className='workBoxImg' src={hbibna} alt='' />
                <div className='workBoxTitle'><a href='https://www.hbibna.com/' target='_black'>Hbibna.com</a></div>
                <div className='workBoxDesc'>My e-commerce website is your kitchen's best friend. We specialize in a handpicked selection of top-notch electrominage products that elevate your culinary game. With HTML, CSS, JavaScript, PHP, and SQL as our secret ingredients, we've cooked up a user-friendly and secure platform. It's the go-to destination for enhancing your kitchen and cooking experience</div>
                <div className='workBoxHachtag'>
                    <div className='workBoxHachtag1'>#HTML</div>
                    <div className='workBoxHachtag2'>#CSS</div>
                    <div className='workBoxHachtag3'>#JavaScript</div>
                    <div className='workBoxHachtag4'>#Php</div>
                    <div className='workBoxHachtag5'>#Sql</div>
                </div>
            </div>

            <div className='workBox'>
                <img className='workBoxImg' src={portfolio} alt='' />
                <div className='workBoxTitle'><a href='www.rayenbenhassen.com/' target='_black'>Rayen Benhassen.com</a></div>
                <div className='workBoxDesc'>My portfolio website is a showcase of my creative journey, meticulously crafted with React. It's not just a website; it's a visual narrative of my skills and passion. Here, you'll find a collection of my best work, from web development projects to design endeavors. Explore and get to know me through my creations, as I continue to evolve and innovate in the digital realm</div>
                <div className='workBoxHachtag'>
                    <div className='workBoxHachtag1'>#React</div>
                    <div className='workBoxHachtag2'>#CSS</div>
                </div>
            </div>

            

        </div>
    </section>
  )
}

export default works