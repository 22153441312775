import React from 'react';
import './skills.css';
import UIDesign from '../../assets/ui-design.png';
import WebDesign from '../../assets/website-design.png';
import AppbDesign from '../../assets/app-design.png';

function Skills() {
  return (
    <section id='skills'>
        <span className='skillTitle'> What I do </span>
        <span className='skillDescription'> Passionate and skilled Full-Stack Developer with a strong foundation in both front-end and back-end web development. Proficient in a wide range of programming languages, frameworks, and tools like <span>HTML, CSS, JavaScript, PHP, SQL,React </span>as well as design software such as <span>Adobe Photoshop, Illustator, Adobe Premier Pro </span>. Committed to delivering high-quality and user-friendly digital solutions. Adept at collaborating with cross-functional teams to bring projects from concept to completion.
         </span>
         <div className='skillBars'>
            
            <div className='skillBar'>
                <img className='skillBarImg' src={WebDesign} alt='WebDesign'/>
                <div className='skillBarText'>
                    <h2>Frontend Developer</h2>
                    <p>" I am a seasoned Frontend Developer with a strong focus on creating captivating user interfaces and seamless experiences. My skills encompass <span>HTML, CSS, and JavaScript</span>, bolstered by proficiency in contemporary web development tools. I excel in translating design ideas into responsive and visually stunning web designs that captivate and engage users. "</p>
                </div>
            </div>

            <div className='skillBar'>
                <img className='skillBarImg' src={UIDesign} alt='UIDesign'/>
                
                <div className='skillBarText'>
                    <h2>Backend Developer</h2>
                    <p>" I am a seasoned developer with expertise in <span>PHP and SQL</span>. Over the years, I've mastered the art of creating robust web applications with efficient backend systems. My skillset includes proficient database management, secure data handling, and server-side scripting. I take pride in delivering reliable and scalable solutions that drive exceptional user experiences. "</p>
                </div>
            </div>

            <div className='skillBar'>
                <img className='skillBarImg'  src={AppbDesign} alt='AppbDesign' />
                <div className='skillBarText'>
                    <h2>desinger</h2>
                    <p>Design is more than just a hobby for me; it's a passion that I pursue with dedication. I'm proficient in using tools like <span>Adobe Photoshop, Illustrator, and Adobe Premiere Pro</span> to bring my creative ideas to life. Through my hobby, I've had the opportunity to embark on various projects, such as creating captivating advertisement videos for my e-commerce website, <a href='https://www.hbibna.com/' target='_black'>Hbibna.com</a> . This journey allows me to continuously refine my design skills while adding a touch of innovation to my projects, ultimately enhancing the user experience and engagement on my platform</p>
                </div>
            </div>

         </div>
        



    </section>
  )
}

export default Skills